import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

// ========== define route==========
const routes = [
    {
        path: '/', component: () => import('./../views/frontend/Layout'),
        // chlidren menu
        children: [
            {
                path: '/', name: 'home.page',
                component: () => import('./../views/frontend/Home')
            },
            // ------------------Content portion------------------
            {
                path: '/content/:slug', name: 'content',
                component: () => import('./../views/frontend/Content/Content')
            },
            {
                path: '/capacity-building', name: 'capacity-building',
                component: () => import('./../views/frontend/Content/CapacityBuilding')
            },
            // ------------------Pages------------------
            {
                path: '/enlisted-company', name: 'enlisted-company',
                component: () => import('./../views/frontend/Company/EnlistedCompany')
            },
            {
                path: '/events', name: 'events',
                component: () => import('./../views/frontend/Event/Events')
            },
            {
                path: '/event-details/:slug', name: 'eventDetails',
                component: () => import('./../views/frontend/Event/Details')
            },
            {
                path: '/training', name: 'training',
                component: () => import('./../views/frontend/Training/Training')
            }, {
                path: '/training-details/:slug', name: 'trainingDetails',
                component: () => import('./../views/frontend/Training/Details')
            },
            {
                path: '/contact', name: 'contact',
                component: () => import('./../views/frontend/Contact')
            },
            { // ------------------User Signup------------------
                path: '/signup', name: 'signup',
                component: () => import('./../views/frontend/Login/Signup')
            },
            { // ------------------User Login------------------
                path: '/login-panel', name: 'login-panel',
                component: () => import('./../views/frontend/Login/LoginPanel')
            },
            {
                path: '/member-login', name: 'memberLogin',
                component: () => import('./../views/frontend/Login/Login')
            },
            {
                path: '/b2b-login', name: 'b2bLogin',
                component: () => import('./../views/frontend/Login/Login')
            },
            {
                path: '/comapny-registration', name: 'comapny.registration',
                component: () => import('./../views/frontend/Company/BD/Registration')
            },
            {
                path: '/bdschedule-list', name: 'comapny.bdschedule',
                component: () => import('./../views/frontend/Company/BD/ScheduleMatching')
            },
            {
                path: '/japan-dashboard', name: 'japan.japanDashboard',
                component: () => import('./../views/frontend/Company/JP/Dashboard')
            },
            {
                path: '/japan-profile', name: 'japan.japanProfile',
                component: () => import('./../views/frontend/Company/JP/Profile')
            },
            {
                path: '/japan-matching/:slug', name: 'japan.japanMatching',
                component: () => import('./../views/frontend/Company/JP/JapanMatching')
            },
            {
                path: '/japan-matching-list', name: 'japan.japanMatchingList',
                component: () => import('./../views/frontend/Company/JP/JapanMatchingList')
            },
            {
                path: '/search', name: 'search',
                component: () => import('./../views/frontend/Search')
            },
        ],
    },
    {
        path: '/member-profile/:slug', name: 'memberProfile',
        component: () => import('./../views/frontend/Company/MemberProfile')
    },
    {
        path: '/member-profile/:slug/:jp', name: 'memberProfilej',
        component: () => import('./../views/frontend/Company/MemberProfile')
    }
]
// ========== define router==========
const router = new VueRouter({
    routes,
    hashbang: false,
    mode: 'history',
    base: process.env.MIX_VUE_ROUTER_BASE,
    linkExactActiveClass: "active",
    scrollBehavior() {
        return { x: 0, y: 0 };
    }
});

router.beforeEach((to, from, next) => {
    next();
    // active parent menu
    // setTimeout(() => {
    //     $('.nav-item').children('.nav-link').removeClass('parent-active')
    //     $('.router-link-exact-active').parents('.dropdown-menu').siblings('#navbarDropdown').addClass('parent-active');
    // }, 100);
    $('#mobile-nav').attr("id","nav-menu-container")
    $('#mobile-body-overly').css("display","none")
    $('.closebutton').css("display","none")
    $('.openbutton').css("display","initial")
})

export default router
